import { PortalRole } from '../../shared/constants/portal-role';

export interface User {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  picture: string;
  hasPassword: boolean;
  agreedWithLatestTerms: boolean;
  agreedWithLatestPrivacy: boolean;
  permissions: string[];
  sex: string;
  birthDate: string;
  // TODO change to HealthInfo interface
  healthInfo: any;
  hasPendingDataRequest: boolean;
  createdAt: string;
}

export interface Company {
  id: number;
  employeeId: number;
  name: string;
  logo: string;
  role: PortalRole;
  licencePermissions: string[];
  portalPermissions: string[];
  objectPermissions?: {
    canCreateEvents?: boolean;
    canCreateChallenges?: boolean;
  };
  lastSelectedAt: string;
}

export enum StartScreens {
  COMMUNITY_MEMBERS = 'COMMUNITY_MEMBERS',
  TEAMS = 'TEAMS',
  ATTRIBUTES = 'ATTRIBUTES',
  BLOGS = 'BLOGS',
  EVENTS = 'EVENTS',
  CHALLENGES = 'CHALLENGES',
  HEALTH_INSIGHTS = 'HEALTH_INSIGHTS',
  INDIVIDUAL_HEALTH_INSIGHTS = 'INDIVIDUAL_HEALTH_INSIGHTS',
  ROLES = 'ROLES',
}

export interface Preferences {
  shownStartScreens: StartScreens[];
  language: string;
}

export interface CompanyPreferences {
  filters?: {
    screen?: string;
    status?: string;
    query?: string;
    additionalColumns?: {
      [key: string]: boolean | string[];
    };
    teamIds?: number[];
  }[];
}
