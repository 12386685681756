import {
  createFeature,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from '@ngrx/store';
import {
  Company,
  Preferences,
  StartScreens,
  User,
  CompanyPreferences,
} from './user.model';
import { UserActions } from './user.actions';

export const usersFeatureKey = 'user';

export interface UserState {
  accessToken: string;
  refreshToken: string;
  user: User;
  companies: Company[];
  currentCompany: Company;
  companyPreferences: CompanyPreferences;
  preferences: Preferences;
  // additional entities state properties
}

export const initialState: UserState = {
  accessToken: localStorage.getItem('accessToken') || '',
  refreshToken: localStorage.getItem('refreshToken') || '',
  user: null,
  companies: [],
  currentCompany: null,
  companyPreferences: null,
  preferences: {
    shownStartScreens: Object.values(StartScreens),
    language: 'no',
  },
  // additional entity state properties
};

export const reducer = createReducer(
  initialState,
  on(UserActions.addUserSuccess, (state, action) => ({
    ...state,
    user: action.user,
  })),
  on(UserActions.loginUser, (state, action) => ({
    ...state,
    accessToken: action.accessToken,
    refreshToken: action.refreshToken,
  })),
  on(UserActions.logoutUser, (state) => ({
    ...state,
    accessToken: '',
    refreshToken: '',
    user: null,
  })),
  on(UserActions.loadUserCompaniesSuccess, (state, action) => ({
    ...state,
    companies: action.companies,
  })),
  on(UserActions.setCurrentCompany, (state, action) => ({
    ...state,
    currentCompany: action.company,
  })),
  on(UserActions.loadCompanyPreferencesSuccess, (state, action) => ({
    ...state,
    companyPreferences: action.preferences,
  })),
  on(UserActions.loadUserPreferencesSuccess, (state, action) => ({
    ...state,
    preferences: action.preferences,
  })),
);

export const usersFeatureSelector =
  createFeatureSelector<UserState>(usersFeatureKey);

export const usersFeature = createFeature({
  name: usersFeatureKey,
  reducer,
  extraSelectors: () => {
    return {
      selectUserFeature: (state: UserState) => state.user,
      selectUser: createSelector(
        usersFeatureSelector,
        (state: UserState) => state.user,
      ),
      selectAccessToken: createSelector(
        usersFeatureSelector,
        (state: UserState) => state.accessToken,
      ),
      selectRefreshToken: createSelector(
        usersFeatureSelector,
        (state: UserState) => state.refreshToken,
      ),
      selectCompanies: createSelector(
        usersFeatureSelector,
        (state: UserState) => state.companies,
      ),
      selectCurrentCompany: createSelector(
        usersFeatureSelector,
        (state: UserState) => state.currentCompany,
      ),
      selectPreferences: createSelector(
        usersFeatureSelector,
        (state: UserState) => state.preferences,
      ),
      selectCompanyPreferences: createSelector(
        usersFeatureSelector,
        (state: UserState) => state.companyPreferences,
      ),
    };
  },
});

export const {
  selectUserFeature,
  selectUser,
  selectAccessToken,
  selectRefreshToken,
  selectCompanies,
  selectCurrentCompany,
  selectPreferences,
  selectCompanyPreferences,
} = usersFeature;

export const metaLogoutUser = (reducer) => {
  return (state, action) => {
    return reducer(
      action.type === UserActions.logoutUser.type ? {} : state,
      action,
    );
  };
};
