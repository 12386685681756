export const environment = {
  apiUrl: 'https://api.mia.lanars-stage.com/',
  apiVersion: 'v1',
  healthApiUrl: 'https://stage.health-api.mementoportal.com/',
  healthApiVersion: 'v2',
  organizationKey: 'mia',
  production: true,
  mapsPublicKey: 'AIzaSyBoH9SpF0r3ty2L1CRp37LxZduGZ3m9MlY',
  SOCKET_URL: 'https://chats.mia.lanars-stage.com',
};
