import { Attribute, Injectable, NgZone } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import {
  concatMap,
  switchMap,
  tap,
  catchError,
  withLatestFrom,
} from 'rxjs/operators';
import { Observable, EMPTY, of } from 'rxjs';
import { UserActions } from './user.actions';
import { SystemAlertsActions } from '@state/system-alerts/system-alerts.actions';
import { changeLanguage } from '@state/language.actions';
import { selectPreferences } from '@state/user/user.reducer';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { API_ROUTES } from '@shared/constants/api-routes';
import { Company } from './user.model';
import { UserService } from '../../shared/services/user.service';
import { selectCurrentCompany } from './user.reducer';
import { CompanyAttributesActions } from '../company-attributes/company-attributes.actions';
import { TeamsActions } from '@state/teams/teams.actions';

@Injectable()
export class UserEffects {
  loginUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loginUser),
      concatMap((data) => {
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);

        localStorage.removeItem('email');
        localStorage.removeItem('sessionId');
        localStorage.removeItem('sessionIdTTL');

        this.zone.run(() => {
          this.router.navigate(['/portal']);
        });

        return [UserActions.addUser(), UserActions.loadUserCompanies()];
      }),
    );
  });

  refreshAuthTokens$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.refreshTokenSuccess),
      concatMap((data) => {
        localStorage.setItem('accessToken', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        return EMPTY as Observable<{ type: string }>;
      }),
    );
  });

  logoutUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.logoutUser),
      switchMap(({ clearTokensOnly }) => {
        const refreshToken = localStorage.getItem('refreshToken');

        if (clearTokensOnly) {
          return of(1);
        } else {
          return this.http.post(API_ROUTES.AUTH.LOGOUT, {
            refreshToken,
          });
        }
      }),
      catchError(() => of()),
      tap(() => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
      }),

      concatMap(() => {
        this.zone.run(() => {
          this.router.navigate(['/login']);
        });

        return EMPTY as Observable<{ type: string }>;
      }),
    );
  });

  addUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.addUser),
      switchMap(() => this.userService.getMe()),
      concatMap((response) => {
        return [
          UserActions.addUserSuccess({
            user: response,
          }),
          UserActions.loadUserCompanies(),
          UserActions.loadUserPreferences(),
        ];
      }),
    );
  });

  loadUserCompanies$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUserCompanies),
      switchMap(() => this.userService.getMyCompanies()),
      concatMap(({ data }) => {
        if (data.length === 0) {
          return [UserActions.logoutUser({})];
        }
        return [
          UserActions.setCurrentCompany({
            company: data[0],
          }),
          UserActions.loadCompanyPreferences({
            companyId: data[0].id,
          }),
          UserActions.loadUserCompaniesSuccess({
            companies: data,
          }),
          CompanyAttributesActions.getCompanyAttributes({
            companyId: data[0].id,
          }),
        ];
      }),
    );
  });

  selectCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.selectCompany),
      switchMap(({ id }) => this.userService.selectCompany(id)),
      concatMap((response: { data: Company[] }) => {
        return [
          TeamsActions.clearTeams(),
          UserActions.setCurrentCompany({
            company: response.data[0],
          }),

          UserActions.loadCompanyPreferences({
            companyId: response.data[0].id,
          }),
          UserActions.loadUserCompaniesSuccess({
            companies: response.data,
          }),
          CompanyAttributesActions.getCompanyAttributes({
            companyId: response.data[0].id,
          }),
        ];
      }),
    );
  });

  loadCompanyPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadCompanyPreferences),
      switchMap(({ companyId }) =>
        this.userService.getCompanyPreferences(companyId),
      ),
      concatMap((response) => {
        return [
          UserActions.loadCompanyPreferencesSuccess({
            preferences: response,
          }),
        ];
      }),
    );
  });

  updateCompanyPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateCompanyPreferences),
      withLatestFrom(this.store.select(selectCurrentCompany)),
      switchMap(([{ preferences }, company]) => {
        return this.userService.setCompanyPreferences(company.id, preferences);
      }),
      concatMap((response) => {
        return [
          UserActions.loadCompanyPreferencesSuccess({
            preferences: response,
          }),
        ];
      }),
      catchError(({ error }) => {
        return [
          SystemAlertsActions.showErrorSystemAlert({
            title: error.title,
            message: error.detail,
          }),
        ];
      }),
    );
  });

  loadUserPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.loadUserPreferences),
      switchMap(() => this.userService.getMyPreferences()),
      concatMap((response) => {
        return [
          UserActions.loadUserPreferencesSuccess({
            preferences: response,
          }),
        ];
      }),
    );
  });

  updateUserLanguagePreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateUserLanguagePreferences),
      switchMap((preferences) =>
        this.userService.setMyPreferences(preferences),
      ),
      concatMap((response) => {
        return [
          UserActions.loadUserPreferencesSuccess({
            preferences: response,
          }),
          changeLanguage({ language: response.language }),
        ];
      }),
      catchError(({ error }) => {
        return [
          SystemAlertsActions.showErrorSystemAlert({
            title: error.title,
            message: error.detail,
          }),
        ];
      }),
    );
  });

  updateUserStartScreensPreferences$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.updateUserStartScreensPreferences),
      withLatestFrom(this.store.select(selectPreferences)),
      switchMap(([action, preference]) => {
        return this.userService.setMyPreferences({
          shownStartScreens: [...preference.shownStartScreens, action.page],
        });
      }),
      concatMap((response) => {
        return [
          UserActions.loadUserPreferencesSuccess({
            preferences: response,
          }),
        ];
      }),
      catchError(({ error }) => {
        return [
          SystemAlertsActions.showErrorSystemAlert({
            title: error.title,
            message: error.detail,
          }),
        ];
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private http: HttpClient,
    private userService: UserService,
    public store: Store,
    private zone: NgZone,
  ) {}
}
